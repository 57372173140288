import { addDoc, collection } from "firebase/firestore";

import { db } from "../utils/firebase";

const COLLECTION = "feedback";

export async function createFeedbackDocument(
  email: string | null,
  feedbackType: string,
  feedbackMessage: string
) {
  const docRef = await addDoc(collection(db, COLLECTION), {
    email,
    feedbackType,
    feedbackMessage,
  });

  return docRef.id;
}
