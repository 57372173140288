import { Button } from "flowbite-react";

import GoogleSVG from "./assets/google.svg";

type GoogleSignInButtonProps = {
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const GoogleSignInButton = ({ disabled, onClick }: GoogleSignInButtonProps) => {
  return (
    <Button
      gradientDuoTone="pinkToOrange"
      outline
      className="w-full flex not-prose"
      onClick={onClick}
      disabled={disabled}
    >
      <img src={GoogleSVG} className="inline-block w-6 h-6 pr-2" />
      Continue with Google
    </Button>
  );
};

export default GoogleSignInButton;
