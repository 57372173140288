import { JSONContent } from "@tiptap/react";

export type Settings = {
  supersetsEnabled: boolean;
  soundsEnabled: boolean;
};

export const DEFAULT_SETTINGS: Settings = {
  supersetsEnabled: true,
  soundsEnabled: true,
};

export type WorkoutDocument = {
  id: string;
  created: Date;
  data: JSONContent;
};

export type Workout = {
  exercises: Array<Exercise>;
  title: string;
  stale?: Boolean;
};

export type ParsedWorkoutDocument = WorkoutDocument & {
  workout: Workout;
};

export type Exercise = {
  exerciseId: string;
  index: number;
  name: string;
  selected: Boolean;
  history: Boolean;
  sets: Array<Set>;
  targetSets: Array<Set>;
  superset: number;
};

export type Set = {
  complete: boolean;
  reps: number;
  selected: Boolean;
  weight: number | null;
  seconds: number | null;
};

export type SetIndex = {
  exerciseIndex: number;
  setIndex: number;
};
