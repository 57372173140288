import { JSONContent } from "@tiptap/react";

import {
  getExerciseFromNodeIndex,
  parseCompletedSetsText,
} from "../document-to-workout";
import { formatExerciseNode } from "../workout-to-document";

export function formatDurationString(seconds: number, compact = false) {
  if (seconds % 60 === 0) {
    let minutes = seconds / 60;

    if (compact) return `${minutes}m`;
    return `${minutes} minute${minutes > 1 ? "s" : ""}`;
  }
  if (compact) return `${seconds}s`;
  return `${seconds} second${seconds > 1 ? "s" : ""}`;
}

function flattenNodes(node: JSONContent, nodes: Array<JSONContent> = []) {
  nodes.push(node);
  node.content?.forEach((child) => {
    flattenNodes(child, nodes);
  });
  return nodes;
}

export function getAllNodes(document: JSONContent) {
  return flattenNodes(document);
}

export function countNodes(document: JSONContent) {
  return getAllNodes(document).length;
}

export function getTitleNode(document: JSONContent) {
  return document?.content?.[0];
}

export function getTitleText(document: JSONContent) {
  const titleNode = getTitleNode(document);
  if (titleNode !== undefined) {
    return getNodeInnerText(titleNode);
  }
}

export function getNodeInnerText(document: JSONContent) {
  return document.content?.[0]?.text;
}

export function convertTextToNumber(text: string) {
  if (text.length === 0) return null;
  return Number(text.match(/\d+(\.\d+)?/)?.[0]);
}

function isExerciseNodeText(text: string) {
  return text.includes(":") ? !text.startsWith("http") : false;
}

// Exercise definition: 2nd level node with a colon
export function getAllExerciseNodeIndexes(
  document: JSONContent
): Array<number> {
  let indexes: number[] = [];
  document.content?.forEach((node, index: number) => {
    if (isExerciseNodeText(getNodeInnerText(node) || "")) {
      indexes.push(index);
    }
  });

  return indexes;
}

export function getExerciseNodeIndex(
  document: JSONContent,
  index: number
): number {
  return getAllExerciseNodeIndexes(document)[index];
}

export function getCompletedSetsNode(
  document: JSONContent,
  exerciseIndex: number
): JSONContent | null {
  if (!document.content) throw new Error("Document is empty");
  let exerciseNodeIndexes = getAllExerciseNodeIndexes(document);
  if (
    document.content !== undefined &&
    document.content.length <= exerciseNodeIndexes[exerciseIndex] + 1
  ) {
    return null;
  }
  let completedSetsNode =
    document.content[exerciseNodeIndexes[exerciseIndex] + 1];
  if (
    completedSetsNode !== undefined &&
    !isCompletedSetsNode(completedSetsNode)
  ) {
    return null;
  }

  return completedSetsNode;
}

// A "completed sets" node is the paragraph after an exercise definition
export function isCompletedSetsNode(node: JSONContent) {
  if (node === undefined) return false;
  if (node.type !== "paragraph") return false;
  let completedSetsText = getNodeInnerText(node);
  if (completedSetsText === undefined) return false;

  try {
    parseCompletedSetsText(completedSetsText);
    return true;
  } catch (error) {
    return false;
  }
}

// https://stackoverflow.com/questions/175739/how-can-i-check-if-a-string-is-a-valid-number
export function isNumeric(str: string) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    // @ts-ignore
    !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function setNodeToHeading(node: JSONContent, level: number) {
  node.type = "heading";
  node.attrs = {
    level,
  };
}

export function setNodeToParagraph(node: JSONContent) {
  node.type = "paragraph";
  delete node.attrs;
}

export function setNodeInnerText(node: JSONContent, text: string) {
  if (!node.content) {
    node.content = [{ type: "text" }];
  }
  const textNode = node.content[0];
  if (textNode) {
    textNode.text = text;
  }
  return node;
}

export function updateDateTitleToToday(document: JSONContent): JSONContent {
  const titleNode = getTitleNode(document);
  if (titleNode !== undefined) {
    const title = getNodeInnerText(titleNode);
    if (title !== undefined && isValidDateTitle(title)) {
      setNodeInnerText(titleNode, generateDateTitle());
    }
  }
  return document;
}

export function isValidDateTitle(str: string) {
  // Regular expression to match the pattern "Month Day, Year"
  const dateRegex =
    /^(January|February|March|April|May|June|July|August|September|October|November|December)\s\d{1,2},\s\d{4}$/;

  // Test the input string against the regular expression and return the result
  return dateRegex.test(str);
}

export function generateDateTitle() {
  return new Date().toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

// Don't allow user to create their own headings, only configure them automatically from exercises and the title.
export function removeHeadings(document: JSONContent) {
  let nodes = getAllNodes(document);
  nodes.forEach((node) => {
    if (node.type == "heading") {
      setNodeToParagraph(node);
    }
  });
  return document;
}

export function removeInvalidExercises(document: JSONContent) {
  let nodes = getAllNodes(document);
  nodes.forEach((node) => {
    if (node.type == "exercise") {
      const text = getNodeInnerText(node);
      if (text === undefined || !text.includes(":")) setNodeToParagraph(node);
    }
  });
  return document;
}

// Take the JSON tree from the editor and make edits to it, then pass it back.
export function formatWorkoutDocument(document: JSONContent) {
  const transformers = [formatExercises, formatTitle, removeInvalidExercises];
  // const transformers = [removeHeadings, formatExercises, formatTitle];
  // TODO decide if we want other rich text features
  return transformers.reduce(
    (transformedDocument, transformer) => transformer(transformedDocument),
    document
  );
}

export function formatExercises(document: JSONContent) {
  getAllExerciseNodeIndexes(document).forEach((nodeIndex, exerciseIndex) => {
    const exercise = getExerciseFromNodeIndex(
      document,
      nodeIndex,
      exerciseIndex
    );
    const exerciseNode = document.content?.[nodeIndex];
    if (exerciseNode !== undefined) {
      formatExerciseNode(exerciseNode, exercise);
    }
  });

  return document;
}

export function removeExerciseCompletionNodes(
  document: JSONContent
): JSONContent {
  if (document.content === undefined)
    throw new Error("Document content is undefined");

  const exerciseNodeIndexes = getAllExerciseNodeIndexes(document);

  for (let i = document.content.length - 1; i >= 0; i--) {
    if (
      exerciseNodeIndexes.some(
        (exerciseNodeIndex) => i === exerciseNodeIndex + 1
      )
    ) {
      if (isCompletedSetsNode(document.content[i])) {
        document.content.splice(i, 1);
      }
    }
  }

  return document;
}

// The first line must always be an H1.
export function formatTitle(document: JSONContent) {
  const node = getTitleNode(document);
  if (node) {
    setNodeToHeading(node, 1);
  }

  return document;
}
