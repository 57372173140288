import { Button, Label, Select, Textarea } from "flowbite-react";
import { useState } from "react";
import toast from "react-hot-toast/headless";

import { useAuth } from "../../contexts/auth-context";
import { createFeedbackDocument } from "../../services/feedback";

function Feedback() {
  const { currentUser } = useAuth();
  if (currentUser === null) throw new Error("User not logged in.");

  const [feedbackType, setFeedbackType] = useState("General");
  const [feedbackMessage, setFeedbackMessage] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    try {
      await createFeedbackDocument(
        currentUser.email,
        feedbackType,
        feedbackMessage
      );
      setFeedbackType("General");
      setFeedbackMessage("");
      setSubmitted(true);
    } catch (err) {
      toast.error("Failed to submit feedback");
      console.error(err);
    }
  };

  if (submitted) {
    return (
      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
          <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
            Thank you for your Feedback!
          </h1>
          <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
            <div className="py-2.5">
              <Button
                gradientDuoTone="pinkToOrange"
                type="submit"
                className="w-full"
                size="xl"
                href="/"
              >
                Go Home
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Leave Us Feedback
        </h2>
        <p className="mb-8 lg:mb-16 font-light text-center text-gray-500 dark:text-gray-400 sm:text-xl">
          Encountered a technical issue? Have an idea for a new feature? Let us
          know.
        </p>
        <form onSubmit={handleSubmit} className="space-y-8">
          <div>
            <div className="mb-2 block">
              <Label htmlFor="feedback-type" value="Feedback Type" />
            </div>
            <Select
              id="feedback-type"
              required={true}
              onChange={(event) => setFeedbackType(event.target.value)}
            >
              <option value="General">General</option>
              <option value="Bug">Bug</option>
              <option value="Idea">Idea</option>
            </Select>
          </div>
          <div className="sm:col-span-2">
            <div className="mb-2 block">
              <Label htmlFor="comment" value="Your message" />
            </div>
            <Textarea
              id="comment"
              placeholder="Leave a comment..."
              required={true}
              rows={4}
              onChange={(event) => setFeedbackMessage(event.target.value)}
            />
          </div>
          <Button gradientDuoTone="pinkToOrange" type="submit" size="xl">
            Send Feedback 👋
          </Button>
        </form>
      </div>
    </section>
  );
}

export default Feedback;
