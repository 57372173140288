import { Button } from "flowbite-react";

import { useAuth } from "../../contexts/auth-context";

function NotFound() {
  const { isLoggedIn } = useAuth();
  const CTA = isLoggedIn ? "Go to Notebook" : "Get Started";
  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
          Page Not Found!
        </h1>
        <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <div className="py-2.5">
            <Button
              gradientDuoTone="pinkToOrange"
              type="submit"
              className="w-full"
              size="xl"
              href="/"
            >
              Go Home
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
