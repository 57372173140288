import { Button } from "flowbite-react";
import { useNavigate } from "react-router";

function SignInButton() {
  const navigate = useNavigate();

  const signIn = () => {
    navigate("/login");
  };

  return (
    <Button gradientDuoTone="pinkToOrange" onClick={signIn}>
      Sign In
    </Button>
  );
}

export default SignInButton;
