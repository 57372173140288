import { Spinner } from "flowbite-react";
import React from "react";
import { Route, Routes } from "react-router-dom";

import ForgotPassword from "../pages/auth/forgot-password";
import Login from "../pages/auth/login";
import Signup from "../pages/auth/signup";
import Feedback from "../pages/feedback";
import LandingPage from "../pages/landing-page";
import NotFound from "../pages/not-found";
import Settings from "../pages/settings";
import PrivateRoute from "./components/private-route";

function RouteManager() {
  function lazyWithPreload(factory: any) {
    const Component: any = React.lazy(factory);
    Component.preload = factory;
    return Component;
  }

  const LazyWorkout = lazyWithPreload(() => import("../pages/workout"));
  LazyWorkout.preload();
  const LazyWorkouts = lazyWithPreload(() => import("../pages/workouts"));
  LazyWorkout.preload();

  const Fallback = (
    <div className="w-full h-full flex items-center justify-center">
      <Spinner aria-label="Loading" size="xl" />
    </div>
  );
  return (
    <React.Suspense fallback={Fallback}>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/forgot_password" element={<ForgotPassword />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/feedback"
          element={
            <PrivateRoute>
              <Feedback />
            </PrivateRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <PrivateRoute>
              <Settings />
            </PrivateRoute>
          }
        />
        <Route path="/" element={<LandingPage />} />
        <Route
          path="/workouts/:workoutId"
          element={
            <PrivateRoute>
              <LazyWorkout />
            </PrivateRoute>
          }
        />
        <Route
          path="/workouts/"
          element={
            <PrivateRoute>
              <LazyWorkouts />
            </PrivateRoute>
          }
        />
      </Routes>
    </React.Suspense>
  );
}

export default RouteManager;
