type ToggleProps = {
  checked: boolean;
  children: React.ReactNode;
  onToggle: (toggled: boolean) => void;
};

function Toggle({ checked, children, onToggle }: ToggleProps) {
  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <input
        type="checkbox"
        value=""
        className="sr-only peer"
        checked={checked}
        onChange={() => onToggle(!checked)}
      />
      <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-orange-300 dark:peer-focus:ring-orange-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[4px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-orange-500"></div>
      <span className="ml-3 text-large font-medium text-gray-900 dark:text-gray-300">
        {children}
      </span>
    </label>
  );
}

export default Toggle;
