import { Button } from "flowbite-react";
import {
  IoBarChart,
  IoBook,
  IoBuild,
  // IoCloudOffline,
  // IoLockClosed,
  IoTimer,
} from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import YouTube from "react-youtube";

import { useAuth } from "../../contexts/auth-context";

function LandingPage() {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const onCTAClicked = () => {
    navigate("/login");
  };

  const CTA = isLoggedIn ? "Go to Notebook" : "Get Started";

  const videoOpts = {
    height: "600",
    width: "300",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
      controls: 0,
      modestbranding: 1,
    },
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 px-4 mx-auto max-w-screen-xl text-center lg:py-16 lg:px-12">
        <div className="lg:flex lg:justify-center lg:items-center">
          <div className="lg:flex-1">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-gray-900 md:text-5xl lg:text-6xl dark:text-white">
              A better fitness journal
            </h1>
            <p className="mb-8 text-lg font-normal text-gray-500 lg:text-xl sm:px-16 xl:px-48 dark:text-gray-400">
              All of the freedom and simplicity of paper and pen, all of the
              power of an app.
            </p>
            <div className="flex flex-col mb-16 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <div className="py-2.5 not-prose">
                <Button
                  gradientDuoTone="pinkToOrange"
                  type="submit"
                  className="w-full not-prose"
                  size="xl"
                  onClick={onCTAClicked}
                >
                  {CTA}
                </Button>
              </div>
            </div>
          </div>
          <YouTube
            videoId="y9VdOfEXVis"
            opts={videoOpts}
            className="flex justify-center items-center"
          />
        </div>
      </div>
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <div className="max-w-screen-md mb-8 lg:mb-16">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Designed for fitness nerds
          </h2>
          <p className="text-gray-500 sm:text-xl dark:text-gray-400">
            Lift Notes makes tracking your workouts much easier by stuffing all
            of the features of the best fitness apps into a simple text editor.
          </p>
        </div>
        <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-2 md:gap-12 md:space-y-0 bg-gray-50 dark:bg-gray-800 dark:border-gray-700 p-4 lg:p-8">
          {/* <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0 bg-gray-50 dark:bg-gray-800 dark:border-gray-700 p-4 lg:p-8"> */}
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoBook size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              Just a Notebook
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Customizing and tracking your workout is as easy as typing it in,
              as if you were writing it on paper.
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoBuild size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              Few Clicks
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Record your workout with just a few taps. No need to fuss with
              your phone keyboard while you work out.
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoTimer size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              HIIT Timer
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Time exercises with a stopwatch that autoplays and calls out what
              exercise comes next.
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoBarChart size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              Track your progress
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Visualize your improvement with charts and graphs.
            </p>
          </div>
          {/* <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoCloudOffline size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              Works Offline
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Anything you write is auto-saved and uploaded to the cloud when
              you get back to the internet.
            </p>
          </div>
          <div>
            <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-gradient-to-br text-white from-pink-500 to-orange-400 lg:h-12 lg:w-12">
              <IoLockClosed size="1.5em" />
            </div>
            <h3 className="mb-2 text-xl font-bold dark:text-white">
              Own Your Data
            </h3>
            <p className="text-gray-500 dark:text-gray-400">
              Export your notebook as markdown files any time.
            </p>
          </div> */}
        </div>
        <div className="mx-auto my-16 text-center lg:mt-36 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
          <h2 className="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 dark:text-white">
            Ready to give it a try?
          </h2>
          <div className="py-2.5 not-prose inline-block">
            <Button
              gradientDuoTone="pinkToOrange"
              type="submit"
              className="w-full not-prose"
              size="xl"
              onClick={onCTAClicked}
            >
              {CTA}
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default LandingPage;
