import { JSONContent } from "@tiptap/react";
import cloneDeep from "lodash.clonedeep";

import {
  generateDateTitle,
  removeExerciseCompletionNodes,
  updateDateTitleToToday,
} from "./workouts/document-utils";

export const getEmptyWorkoutDoc = (): JSONContent => {
  const title = generateDateTitle();
  return {
    content: [
      {
        content: [
          {
            type: "text",
            text: title,
          },
        ],
        type: "heading",
        attrs: {
          level: 1,
        },
      },
      {
        type: "paragraph",
      },
    ],
    type: "doc",
  };
};

export const cloneWorkoutDoc = (document: JSONContent): JSONContent => {
  const transformers = [
    cloneDeep,
    updateDateTitleToToday,
    removeExerciseCompletionNodes,
  ];
  return transformers.reduce(
    (transformedDocument, transformer) => transformer(transformedDocument),
    document
  );
};

export const getHelpWorkoutDoc = (): JSONContent => {
  return {
    type: "doc",
    content: [
      {
        type: "heading",
        attrs: {
          level: 1,
        },
        content: [
          {
            type: "text",
            text: "Tutorial",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "(3 minute read)",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Lift Notes is a virtual fitness tracker that works exactly like a paper notebook. Each time you work out, just add a new page. It automatically saves your changes to the cloud.",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Jot down a workout plan on a new page. Then, at the gym, Lift Notes will provide tools to execute your workout and record your progress in only a few clicks.",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "To get started, you can add new exercises to your workout by writing the exercise name followed by a colon.",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "mj2dx",
          editButtonClasses: "help help-1",
          classes: "help help-0",
        },
        content: [
          {
            type: "text",
            text: "Back Squats: 3x8 (250 lbs)",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: 'Lift Notes recognizes that I\'ve added "Back Squats" as a new exercise.',
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "Click the flashing beacon to learn a bit about how exercises work.",
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "Training Notation",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "To design your workout in Lift Notes, you'll need to learn the basics of training notation.",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "wui6m",
        },
        content: [
          {
            type: "text",
            text: "Bench Press: 3x8",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: 'Here, "3x8" indicates I plan to do 3 sets of 8 reps each.',
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "r5cw0i",
        },
        content: [
          {
            type: "text",
            text: "Bicep Curls: 3x10(30 lbs)",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "To indicate weight, use parentheses.",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "s5x4mp",
          editButtonClasses: "help help-9",
        },
        content: [
          {
            type: "text",
            text: "Wall Sit: 1 minute",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "For sets that are performed for time instead of reps, you can indicate the duration using either minutes (m/min/minutes) or seconds (s/sec/seconds).",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "gxm7r",
          historyButtonClasses: "help help-11",
        },
        content: [
          {
            type: "text",
            text: "Calf Raise Hold: 3x30 seconds (10 lbs)",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "You can mix and match, performing multiple timed, weighted sets.",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "mjdpt",
        },
        content: [
          {
            type: "text",
            text: "Pull Ups: 8/8/10",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "For exercises where I want to do a different number of reps per set, slashes can be used. Here 8/8/10 denotes 2 sets of 8 reps followed by a set of 10 reps.",
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "Planned and completed sets",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "kk0mk",
        },
        content: [
          {
            type: "text",
            text: "Pistol Squats: 3x5",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "5/5/3",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "By convention, the line after the exercise name shows what sets were actually completed. Lift Notes can fill this in automatically. Here, I planned to do three sets of five pistol squats, but only managed three on my third set.",
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 1,
        },
        content: [
          {
            type: "text",
            text: "Supersets",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: 'Lift notes will treat all sets grouped under a heading as a superset. Choosing "next set" or autoplaying timed exercises within a superset will behave as you would expect. This behavior can be turned off in user settings. For example...',
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "Superset A",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "sakfk4k",
        },
        content: [
          {
            type: "text",
            text: "Split Squats: 3x5",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "sr783ld",
        },
        content: [
          {
            type: "text",
            text: "Push Ups: 3x20",
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "Superset B",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "sp5j39f",
        },
        content: [
          {
            type: "text",
            text: "Skull Crushers: 3x8",
          },
        ],
      },
      {
        type: "exercise",
        attrs: {
          exerciseId: "ggktke3",
        },
        content: [
          {
            type: "text",
            text: "Bicep Curls: 3x8",
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "Markdown syntax",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: 'Lift notes uses a rich text editor that supports a subset of markdown syntax. To add a heading, use hash symbols e.g., "## Superset A"',
          },
        ],
      },
      {
        type: "heading",
        attrs: {
          level: 2,
        },
        content: [
          {
            type: "text",
            text: "That's it!",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            type: "text",
            text: "To close the tutorial, toggle the help button at the top of the page.",
          },
        ],
      },
    ],
  };
};
