import { JSONContent } from "@tiptap/react";
import cloneDeep from "lodash.clonedeep";

import { Exercise, Set, Workout } from "../../../types";
import {
  formatDurationString,
  getAllExerciseNodeIndexes,
  getCompletedSetsNode,
  getExerciseNodeIndex,
  isCompletedSetsNode,
  setNodeInnerText,
} from "../document-utils";

export function writeWorkoutToDocument(
  document: JSONContent,
  workout: Workout
) {
  let doc = cloneDeep(document);
  for (let i in workout.exercises) {
    doc = setExerciseContent(doc, workout.exercises[i]);
  }
  return doc;
}

export function setExerciseContent(document: JSONContent, exercise: Exercise) {
  const exerciseNodeIndex = getExerciseNodeIndex(document, exercise.index);
  const exerciseNode = document.content?.[exerciseNodeIndex];
  if (!exerciseNode) throw new Error("Exercise not found in document");
  formatExerciseNode(exerciseNode, exercise);

  const completedSets = exercise.sets.filter((s) => s.complete);
  const completedSetsText = formatSetsText(completedSets);
  if (completedSetsText.length > 0) {
    const completedSetsNode = getOrCreateCompletedSetsNode(document, exercise);
    setNodeInnerText(completedSetsNode, completedSetsText);
  } else {
    // Delete the completed sets node if its text was reduced to nothing.
    const completedSetsNode = document.content?.[exerciseNodeIndex + 1];
    if (
      completedSetsNode !== undefined &&
      isCompletedSetsNode(completedSetsNode)
    ) {
      document.content?.splice(exerciseNodeIndex + 1, 1);
    }
  }
  return document;
}

export function formatExerciseNode(node: JSONContent, exercise: Exercise) {
  let title = formatExerciseText(exercise.name, exercise.targetSets);

  function generateId() {
    return (Math.random() + 1).toString(36).substring(7);
  }

  node.type = "exercise";
  if (!node.attrs?.exerciseId) {
    node.attrs = {
      exerciseId: generateId(),
    };
  }

  setNodeInnerText(node, title);
  return node;
}

export function formatExerciseText(
  exerciseName: string,
  targetSets: Array<Set>
) {
  let text = exerciseName + ": ";
  let setsText = formatSetsText(targetSets);
  if (setsText !== "1") text += setsText;

  return text.trim();
}

export function formatSetsText(sets: Array<Set>) {
  let text = "";

  const duplicateSets =
    sets.length > 1 &&
    sets.every((set) => {
      return (
        set.seconds === sets[0].seconds &&
        set.reps === sets[0].reps &&
        set.weight === sets[0].weight
      );
    });

  // "3x8" format
  if (duplicateSets) {
    text += sets.length + "x";
    text += formatSetText(sets[0], false);
  } else if (sets.length !== 1) {
    // "8/8/10" format
    let setStrings = sets.map((set) => formatSetText(set, true));
    text += setStrings.join("/");
  } else {
    // single set
    text += formatSetText(sets[0], false);
  }

  return text.trim();
}

export function formatSetText(set: Set, compact = false): string {
  let text = "";
  if (set.seconds !== null) {
    text += formatDurationString(set.seconds, compact);
  } else if (set.reps === 1 && set.weight !== null) {
    return `${set.weight} lbs`;
  } else {
    text += set.reps;
  }

  if (set.weight !== null) {
    // Truncate to one decimal place
    text += `(${Math.round(set.weight * 10) / 10} lbs)`;
  }

  return text;
}

export function getOrCreateCompletedSetsNode(
  document: JSONContent,
  exercise: Exercise
) {
  let completedSetsNode = getCompletedSetsNode(document, exercise.index);

  if (completedSetsNode !== null) {
    return completedSetsNode;
  }
  return createCompletedSetsNode(document, exercise);
}

export function createCompletedSetsNode(
  document: JSONContent,
  exercise: Exercise
) {
  let exerciseNodeIndex = getAllExerciseNodeIndexes(document)[exercise.index];
  const emptyParagraph = {
    type: "paragraph",
  };
  if (!document.content) throw new Error("Document is empty");
  document.content.splice(exerciseNodeIndex + 1, 0, emptyParagraph);
  return document.content[exerciseNodeIndex + 1];
}
