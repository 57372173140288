import { BrowserRouter as Router } from "react-router-dom";

// import Footer from "./common/components/footer";
import NavBar from "./common/components/nav-bar";
import Notifications from "./common/components/notifications";
import { AuthProvider } from "./contexts/auth-context";
import { WorkoutsProvider } from "./contexts/workouts-context";
import RouteManager from "./route-manager";

function App() {
  return (
    <AuthProvider>
      <WorkoutsProvider>
        <Router>
          <div className="bg-white dark:bg-gray-900 h-full overflow-auto flex flex-col">
            <Notifications />
            <NavBar />
            <div className="flex h-full items-top justify-center lg:px-8 px-2 flex-1 w-full mx-auto overflow-auto">
              <div className="w-full h-full">
                <RouteManager />
              </div>
            </div>
            {/* <Footer /> */}
          </div>
        </Router>
      </WorkoutsProvider>
    </AuthProvider>
  );
}

export default App;
