import { Alert, Button, Label, TextInput } from "flowbite-react";
import { useRef, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { IoBarbell } from "react-icons/io5";
import { Link } from "react-router-dom";

import { useAuth } from "../../../contexts/auth-context";

export default function ForgotPassword() {
  const emailRef = useRef<HTMLInputElement>(null);
  const { resetPassword } = useAuth();
  const [error, setError] = useState<string | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await resetPassword(emailRef.current!.value);
      setMessage("Check your inbox for further instructions");
    } catch (err) {
      setError("Failed to reset password");
    }

    setLoading(false);
  }

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 w-full min-h-full">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl text-gray-900 dark:text-white font-bold tracking-wide font-lora"
          >
            <IoBarbell size="1.5em" className="pr-2" />
            Lift Notes
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Reset Password
              </h1>
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                {error && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    <span>{error}</span>
                  </Alert>
                )}
                {message && (
                  <Alert color="info">
                    <span>{message}</span>
                  </Alert>
                )}
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Email" />
                  </div>
                  <TextInput
                    id="email1"
                    type="email"
                    ref={emailRef}
                    placeholder="name@swole.net"
                    required={true}
                    autoComplete="on"
                  />
                </div>
                <div className="py-2.5">
                  <Button
                    disabled={loading}
                    gradientDuoTone="pinkToOrange"
                    type="submit"
                    className="w-full"
                  >
                    Reset Password
                  </Button>{" "}
                </div>
                <div className="w-100 text-center mt-3 text-sm font-light text-gray-500 dark:text-gray-400">
                  <Link
                    to="/login"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Sign in
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
