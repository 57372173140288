import { Navigate } from "react-router-dom";

import { useAuth } from "../../../contexts/auth-context";

type PrivateRouteProps = {
  children?: React.ReactNode;
};

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser } = useAuth();
  return <>{currentUser ? children : <Navigate to="/login" />}</>;
};

export default PrivateRoute;
