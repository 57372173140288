import { Toast, useToaster } from "react-hot-toast/headless";

import ErrorNotification from "./components/error-notification";
import GenericNotification from "./components/generic-notification";

type NotificationProps = {
  toast: Toast;
};

const Notification = ({ toast }: NotificationProps) => {
  if (toast.type === "error")
    return <ErrorNotification message={String(toast.message)} />;
  return <GenericNotification message={String(toast.message)} />;
};

function Notifications() {
  const { toasts, handlers } = useToaster();
  const { startPause, endPause } = handlers;
  return (
    <div
      className="fixed top-16 left-2 z-50"
      onMouseEnter={startPause}
      onMouseLeave={endPause}
    >
      {toasts.map((toast) => {
        return <Notification key={toast.id} toast={toast} />;
      })}
    </div>
  );
}

export default Notifications;
