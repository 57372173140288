import { Toast } from "flowbite-react";

type ErrorNotificationProps = {
  message: string;
};

const ErrorNotification = ({ message }: ErrorNotificationProps) => {
  return (
    <Toast className="mt-2 outline text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 dark:bg-gray-800 dark:text-red-400 dark:border-red-800">
      {message}
      <Toast.Toggle className="ml-2 bg-red-50 text-red-500 focus:ring-2 focus:ring-red-400 hover:bg-red-200 hover:text-red-500 inline-flex h-8 w-8 dark:bg-gray-800 dark:text-red-400 dark:hover:bg-gray-700" />
    </Toast>
  );
};

export default ErrorNotification;
