import { Spinner } from "flowbite-react";

import { useAuth } from "../../contexts/auth-context";
import Toggle from "./components/toggle";

function Settings() {
  const { currentUser, currentUserSettings, updateSettings } = useAuth();
  if (currentUser === null) throw new Error("User not logged in.");

  const onSupersetsToggled = (toggled: boolean) => {
    updateSettings({ supersetsEnabled: toggled });
  };

  const onSoundsToggled = (toggled: boolean) => {
    updateSettings({ soundsEnabled: toggled });
  };

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="py-8 lg:py-16 px-4 mx-auto max-w-screen-md">
        <h2 className="mb-16 text-4xl tracking-tight font-extrabold text-center text-gray-900 dark:text-white">
          Account Settings
        </h2>
        {currentUserSettings === null ? (
          <div className="flex flex-col gap-2 my-10">
            <div className="text-center">
              <Spinner size="xl" aria-label="loading" />
            </div>
          </div>
        ) : (
          <div>
            <div className="flex flex-col md:flex-row md:items-center">
              <div>
                <Toggle
                  checked={currentUserSettings.supersetsEnabled}
                  onToggle={onSupersetsToggled}
                >
                  Supersets
                </Toggle>
              </div>
              <div className="flex-1 mt-3 md:mt-0 md:ml-10">
                Automatically superset any exercises in between headings. If
                this is turned off, you will complete all sets in an exercise
                before moving on to the next.
              </div>
            </div>
            <div className="flex flex-col md:flex-row md:items-center mt-10">
              <div>
                <Toggle
                  checked={currentUserSettings.soundsEnabled}
                  onToggle={onSoundsToggled}
                >
                  Sounds
                </Toggle>
              </div>
              <div className="flex-1 mt-3 md:mt-0 md:ml-10">
                Enable beeping and saying exercise names during timed exercises.
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}

export default Settings;
