import {
  GoogleAuthProvider,
  User,
  UserCredential,
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  updateEmail as updateFirebaseEmail,
  updatePassword as updateFirebasePassword,
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";

import "../../utils/firebase";

import {
  fetchSettings as fetchSettingsDocument,
  updateSettings as updateSettingsDocument,
} from "../../services/settings";
import { DEFAULT_SETTINGS, Settings } from "../../types";

type AuthContextType =
  | undefined
  | {
      currentUser: User | null;
      currentUserSettings: Settings | null;
      isLoggedIn: Boolean;
      login: (email: string, password: string) => Promise<UserCredential>;
      signup: (email: string, password: string) => Promise<UserCredential>;
      signInWithGoogle: () => Promise<User>;
      logout: () => Promise<void>;
      resetPassword: (email: string) => Promise<void>;
      updateEmail: (email: string) => Promise<void>;
      updatePassword: (password: string) => Promise<void>;
      updateSettings: (settings: Partial<Settings>) => Promise<void>;
    };

const AuthContext = React.createContext<AuthContextType>(undefined);

export function useAuth() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error(
      "The component using the the Auth context must be a descendant of the context provider"
    );
  }
  return context;
}

type AuthProviderProps = {
  children?: React.ReactNode;
};

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [currentUser, setCurrentUser] = useState<User | null>(null);
  const [currentUserSettings, setCurrentUserSettings] =
    useState<Settings | null>(null);
  const [loading, setLoading] = useState(true);

  function signup(email: string, password: string) {
    const auth = getAuth();
    return createUserWithEmailAndPassword(auth, email, password);
  }

  function signInWithGoogle() {
    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    return signInWithPopup(auth, provider).then((result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential?.accessToken;
      // The signed-in user info.
      return result.user;
    });
  }

  function login(email: string, password: string) {
    const auth = getAuth();
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    const auth = getAuth();
    return signOut(auth);
  }

  function resetPassword(email: string) {
    const auth = getAuth();
    return sendPasswordResetEmail(auth, email);
  }

  async function updateEmail(email: string) {
    const auth = getAuth();
    if (currentUser) {
      return updateFirebaseEmail(currentUser, email);
    }
  }

  async function updatePassword(password: string) {
    const auth = getAuth();
    if (currentUser) {
      return updateFirebasePassword(currentUser, password);
    }
  }

  async function updateSettings(settings: Partial<Settings>) {
    if (currentUser === null) throw new Error("Current User is null");
    updateSettingsDocument(currentUser.uid, settings);
    setCurrentUserSettings((prevSettings) => ({
      ...DEFAULT_SETTINGS,
      ...prevSettings,
      ...settings,
    }));
  }

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    async function fetchSettings() {
      if (currentUser === null) return;
      const settings = await fetchSettingsDocument(currentUser.uid);
      setCurrentUserSettings((prevSettings) => ({
        ...DEFAULT_SETTINGS,
        ...prevSettings,
        ...settings,
      }));
    }
    fetchSettings();
  }, [currentUser]);

  const value = {
    currentUser,
    currentUserSettings,
    isLoggedIn: Boolean(currentUser),
    login,
    signup,
    signInWithGoogle,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    updateSettings,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
