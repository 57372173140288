import { Toast } from "flowbite-react";

type GenericNotificationProps = {
  message: string;
};

const GenericNotification = ({ message }: GenericNotificationProps) => {
  return (
    <Toast className="mt-2 border border-gray-300 dark:border-gray-500">
      {message}
      <Toast.Toggle />
    </Toast>
  );
};

export default GenericNotification;
