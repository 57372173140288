import { DarkThemeToggle, Flowbite, Navbar } from "flowbite-react";
import { IoBarbell } from "react-icons/io5";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/auth-context";
import SignInButton from "./components/sign-in-button";
import UserDropdown from "./components/user-dropdown";

function NavBar() {
  const { isLoggedIn } = useAuth();

  const navigate = useNavigate();

  return (
    <Navbar fluid={true} rounded={true}>
      <Navbar.Brand
        href="#"
        onClick={() => {
          navigate("/");
        }}
      >
        <IoBarbell size="1.75em" className="pr-1 font-semibold" />
        <span className="self-center whitespace-nowrap text-xl font-bold tracking-wide font-lora">
          Lift Notes
        </span>
      </Navbar.Brand>
      <div className="flex items-center md:order-2 gap-2 not-prose">
        <Flowbite>
          <DarkThemeToggle />
        </Flowbite>
        {isLoggedIn ? <UserDropdown /> : <SignInButton />}
      </div>
    </Navbar>
  );
}

export default NavBar;
