import { Alert, Button, Label, TextInput } from "flowbite-react";
import { useRef, useState } from "react";
import { HiInformationCircle } from "react-icons/hi";
import { IoBarbell } from "react-icons/io5";
import { Link, Navigate, useNavigate } from "react-router-dom";

import { useAuth } from "../../../contexts/auth-context";
import GoogleSignInButton from "../components/google-sign-in-button";

export default function Login() {
  const emailRef = useRef<HTMLInputElement>(null);
  const passwordRef = useRef<HTMLInputElement>(null);
  const { login, signInWithGoogle, isLoggedIn } = useAuth();
  const [error, setError] = useState<String>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current!.value, passwordRef.current!.value);
      navigate("/workouts");
    } catch (err) {
      setError("Failed to log in");
    }

    setLoading(false);
  }

  async function handleContinueWithGoogleClicked(
    e: React.MouseEvent<HTMLButtonElement>
  ) {
    e.preventDefault();
    try {
      setError("");
      setLoading(true);
      await signInWithGoogle();
      navigate("/workouts");
    } catch (err) {
      setError("Failed to sign in");
    }
    setLoading(false);
  }

  if (isLoggedIn) return <Navigate to="/workouts" />;

  return (
    <>
      <section className="bg-gray-50 dark:bg-gray-900 w-full min-h-full">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto">
          <a
            href="/"
            className="flex items-center mb-6 text-2xl text-gray-900 dark:text-white font-bold tracking-wide font-lora"
          >
            <IoBarbell size="1.5em" className="pr-2" />
            Lift Notes
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                Sign in
              </h1>
              <form onSubmit={handleSubmit} className="flex flex-col gap-4">
                {error && (
                  <Alert color="failure" icon={HiInformationCircle}>
                    <span>{error}</span>
                  </Alert>
                )}
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="email1" value="Email" />
                  </div>
                  <TextInput
                    id="email1"
                    type="email"
                    ref={emailRef}
                    placeholder="name@swole.net"
                    required={true}
                    autoComplete="on"
                  />
                </div>
                <div>
                  <div className="mb-2 block">
                    <Label htmlFor="password1" value="Password" />
                  </div>
                  <TextInput
                    id="password1"
                    type="password"
                    ref={passwordRef}
                    required={true}
                    autoComplete="on"
                  />
                </div>
                <div className="py-2.5">
                  <Button
                    gradientDuoTone="pinkToOrange"
                    disabled={loading}
                    type="submit"
                    className="w-full"
                  >
                    Sign in
                  </Button>{" "}
                </div>
                <div className="flex items-center my-3 before:flex-1 before:border-t before:border-gray-300 before:mt-0.5 after:flex-1 after:border-t after:border-gray-300 after:mt-0.5">
                  <p className="text-center font-semibold mx-4 mb-0 dark:text-white">
                    OR
                  </p>
                </div>
                <GoogleSignInButton
                  disabled={loading}
                  onClick={handleContinueWithGoogleClicked}
                />
                <div className="w-100 text-center mt-3 text-sm font-light text-gray-500 dark:text-gray-400">
                  <Link
                    to="/forgot_password"
                    className="font-medium hover:underline dark:text-white"
                  >
                    Forgot Password?
                  </Link>
                </div>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  Need an account?{" "}
                  <Link
                    to="/signup"
                    className="font-medium hover:underline dark:text-white"
                  >
                    Sign Up
                  </Link>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
