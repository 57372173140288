import { doc, getDoc, setDoc } from "firebase/firestore";

import { Settings } from "../types";
import { db } from "../utils/firebase";

const COLLECTION = "settings";

export async function updateSettings(
  userId: string,
  settings: Partial<Settings>
) {
  await setDoc(doc(db, COLLECTION, userId), settings, { merge: true });
}

export async function fetchSettings(
  userId: string
): Promise<Partial<Settings>> {
  const settingsDocRef = doc(db, COLLECTION, userId);
  const settingsSnapshot = await getDoc(settingsDocRef);
  const snapshotData = settingsSnapshot.data();
  if (!snapshotData) return {};
  return snapshotData;
}
