import { JSONContent } from "@tiptap/react";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast/headless";

import {
  createWorkoutDocument,
  deleteWorkoutDocument,
  fetchWorkoutDocument,
  fetchWorkoutDocuments,
  updateWorkoutDocument,
} from "../../services/workout-documents";
import { ParsedWorkoutDocument } from "../../types";
import { getWorkoutFromDocument } from "../../utils/workouts/document-to-workout";
import { useAuth } from "../auth-context";

type WorkoutContextType =
  | undefined
  | {
      createWorkout: (data?: JSONContent) => Promise<{ id: string }>;
      updateWorkout: (id: string, data: JSONContent) => Promise<void>;
      deleteWorkout: (workoutId: string) => Promise<void>;
      getWorkouts: () => Promise<void>;
      getWorkout: (workoutId: string) => Promise<ParsedWorkoutDocument>;
      workoutDocs: ParsedWorkoutDocument[];
      mostRecentWorkoutDoc?: ParsedWorkoutDocument;
      loading: boolean;
    };

const WorkoutContext = React.createContext<WorkoutContextType>(undefined);

export function useWorkouts() {
  const context = useContext(WorkoutContext);
  if (context === undefined) {
    throw new Error(
      "The component using the the Workouts context must be a descendant of the context provider"
    );
  }
  return context;
}

type WorkoutsProviderProps = {
  children?: React.ReactNode;
};

export const WorkoutsProvider = ({ children }: WorkoutsProviderProps) => {
  const [workoutDocs, setWorkoutDocs] = useState<Array<ParsedWorkoutDocument>>(
    []
  );
  const [mostRecentWorkoutDoc, setMostRecentWorkoutDoc] =
    useState<ParsedWorkoutDocument>();
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser !== null)
      getWorkouts().catch((error) => {
        toast.error("Failed to load workout history");
        console.error(error);
      });
  }, [currentUser]);

  const getWorkouts = async () => {
    setLoading(true);
    const newWorkoutDocs = await fetchWorkouts();
    setWorkoutDocs(newWorkoutDocs);
    setLoading(false);
  };

  const getWorkout = async (workoutId: string) => {
    let localDoc = workoutDocs.find(
      (workoutDoc) => workoutDoc.id === workoutId
    );
    if (localDoc !== undefined) {
      setMostRecentWorkoutDoc(localDoc);
      return localDoc;
    }
    const doc = await fetchWorkoutDocument(workoutId).then((doc) => {
      return {
        id: doc.id,
        created: doc.created,
        data: doc.data,
        workout: getWorkoutFromDocument(doc.data),
      };
    });
    setMostRecentWorkoutDoc(doc);
    return doc;
  };

  const fetchWorkouts = async () => {
    const docs = await fetchWorkoutDocuments();
    return docs.map((doc) => ({
      id: doc.id,
      created: doc.created,
      data: doc.data,
      workout: getWorkoutFromDocument(doc.data),
    }));
  };

  const createWorkout = async (data?: JSONContent) => {
    const doc = await createWorkoutDocument(data);
    const workout = {
      id: doc.id,
      created: doc.created,
      data: doc.data,
      workout: getWorkoutFromDocument(doc.data),
    };
    setWorkoutDocs([workout, ...workoutDocs]);
    return workout;
  };

  const updateWorkout = async (id: string, data: JSONContent) => {
    const index = workoutDocs.findIndex((workoutDoc) => workoutDoc.id === id);
    if (index !== -1) {
      workoutDocs[index].data = data;
      workoutDocs[index].workout = getWorkoutFromDocument(data);
      setWorkoutDocs([...workoutDocs]);
    }

    return await updateWorkoutDocument(id, data);
  };

  const deleteWorkout = async (workoutId: string) => {
    await deleteWorkoutDocument(workoutId);
    const newWorkoutDocs = workoutDocs.filter(
      (workout) => workout.id !== workoutId
    );
    setWorkoutDocs([...newWorkoutDocs]);
  };

  const contextValue = {
    createWorkout,
    updateWorkout,
    deleteWorkout,
    getWorkouts,
    getWorkout,
    workoutDocs,
    mostRecentWorkoutDoc,
    loading,
  };

  return (
    <WorkoutContext.Provider value={contextValue}>
      {children}
    </WorkoutContext.Provider>
  );
};
