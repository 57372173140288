import React, { useRef, useState } from "react";
import toast from "react-hot-toast/headless";
import { useNavigate } from "react-router-dom";

import { useAuth } from "../../../../../contexts/auth-context";
import { onClickOutside } from "../../../../hooks/click-outside";

function UserDropdown() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  const wrapperRef = useRef<HTMLDivElement>(null);
  onClickOutside(wrapperRef, () => {
    setOpen(false);
  });

  const handleToggleDropdownClicked = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setOpen(!open);
  };

  const signOut = async () => {
    try {
      await logout();
      navigate("/");
    } catch {
      toast.error("Failed to log out");
    }
  };

  if (currentUser === null) throw new Error("User not logged in.");

  const photoURL =
    currentUser.photoURL !== null
      ? currentUser.photoURL
      : `https://api.dicebear.com/6.x/initials/svg?seed=${currentUser.email}`;

  return (
    <div ref={wrapperRef}>
      <button
        onClick={handleToggleDropdownClicked}
        type="button"
        className="flex mx-3 text-sm bg-gray-800 rounded-full md:mr-0 focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
        id="user-menu-button"
        aria-expanded="false"
        data-dropdown-toggle="dropdown"
      >
        <span className="sr-only">Open user menu</span>
        <img className="w-8 h-8 rounded-full" src={photoURL} alt="user photo" />
      </button>
      {open && (
        <div
          className="absolute right-2 top-14 z-10 border-2 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700 dark:divide-gray-600 dark:border-gray-500"
          id="dropdown"
        >
          <div className="py-3 px-4">
            {currentUser.displayName !== null ? (
              <>
                <span className="block text-sm font-semibold text-gray-900 dark:text-white">
                  {currentUser.displayName}
                </span>
                <span className="block text-sm font-light text-gray-500 truncate dark:text-gray-400">
                  {currentUser.email}
                </span>
              </>
            ) : (
              <span className="block text-sm font-semibold text-gray-900 dark:text-white">
                {currentUser.email}
              </span>
            )}
          </div>
          <ul
            className="py-1 font-light text-gray-500 dark:text-gray-400"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="/settings"
                className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
              >
                Settings
              </a>
            </li>
            <li>
              <a
                href="/feedback"
                className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:text-gray-400 dark:hover:text-white"
              >
                Leave feedback
              </a>
            </li>
          </ul>
          <ul
            className="py-1 font-light text-gray-500 dark:text-gray-400"
            aria-labelledby="dropdown"
          >
            <li>
              <a
                href="#"
                onClick={signOut}
                className="block py-2 px-4 text-sm hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
              >
                Sign out
              </a>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

export default UserDropdown;
