import { JSONContent } from "@tiptap/react";
import { getAuth } from "firebase/auth";
import {
  Timestamp,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

import { WorkoutDocument } from "../types";
import { db } from "../utils/firebase";
import { getEmptyWorkoutDoc } from "../utils/workouts";

const COLLECTION = "workouts";

export async function fetchWorkoutDocument(
  id: string
): Promise<WorkoutDocument> {
  const workoutDocRef = doc(db, COLLECTION, id);
  const workoutSnapshot = await getDoc(workoutDocRef);
  const snapshotData = workoutSnapshot.data();
  if (!snapshotData) throw new Error("Workout document data is undefined");
  return {
    id: workoutSnapshot.id,
    created: snapshotData.created.toDate(),
    data: snapshotData.data,
  };
}

export async function fetchWorkoutDocuments(): Promise<Array<WorkoutDocument>> {
  const user = getAuth().currentUser;
  if (!user) throw new Error("User is undefined");
  const workoutsRef = collection(db, COLLECTION);
  const workoutsQuery = query(
    workoutsRef,
    where("userId", "==", user.uid),
    orderBy("created", "desc")
  );
  const querySnapshot = await getDocs(workoutsQuery);

  return querySnapshot.docs.map((workoutSnapshot) => {
    const snapshotData = workoutSnapshot.data();
    return {
      id: workoutSnapshot.id,
      created: snapshotData.created.toDate(),
      data: snapshotData.data,
    };
  });
}

export async function updateWorkoutDocument(id: string, data: JSONContent) {
  const workoutDocRef = doc(db, COLLECTION, id);
  return await updateDoc(workoutDocRef, {
    data,
  });
}

export async function createWorkoutDocument(data?: JSONContent) {
  const user = getAuth().currentUser;
  if (!user) {
    throw new Error("User not initialized");
  }

  const docRef = await addDoc(collection(db, COLLECTION), {
    data: data || getEmptyWorkoutDoc(),
    created: Timestamp.now(),
    userId: user.uid,
  });

  return fetchWorkoutDocument(docRef.id);
}

export async function deleteWorkoutDocument(id: string) {
  const workoutDocRef = doc(db, COLLECTION, id);

  return await deleteDoc(workoutDocRef);
}
