import { JSONContent } from "@tiptap/react";
import cloneDeep from "lodash.clonedeep";

import { Exercise, Set, Workout } from "../../../types";
import {
  convertTextToNumber,
  getAllExerciseNodeIndexes,
  getNodeInnerText,
  getTitleText,
  isNumeric,
} from "../document-utils";

const TIME_REGEX =
  "s|\\s*sec|\\s*second|\\s*seconds|m|\\s*min|\\s*minute|\\s*minutes";

export function getExerciseFromNodeIndex(
  document: JSONContent,
  nodeIndex: number,
  exerciseIndex: number
): Exercise {
  const exerciseNode = document.content?.[nodeIndex];
  if (exerciseNode === undefined) throw new Error("exercise node not found");
  const exerciseText = getNodeInnerText(exerciseNode) || "";

  let { exerciseName, targetSets } = parseExerciseText(exerciseText);

  let completedSets =
    getCompletedSetsFromNodeIndex(document, nodeIndex + 1) || [];

  let sets = cloneDeep(targetSets);

  completedSets.forEach((set, index) => {
    if (index >= sets.length)
      sets.push({
        complete: false,
        reps: 0,
        weight: null,
        selected: false,
        seconds: null,
      });

    sets[index].complete = true;
    sets[index].reps = set.reps;
    sets[index].weight = set.weight;
    sets[index].seconds = set.seconds;
  });

  return {
    exerciseId: exerciseNode.attrs?.exerciseId,
    index: exerciseIndex,
    name: exerciseName,
    sets,
    selected: false,
    history: false,
    targetSets,
    superset: getSupersetFromNodeIndex(document, nodeIndex),
  };
}

export function getSupersetFromNodeIndex(
  document: JSONContent,
  nodeIndex: number
) {
  // Sort exercises into supersets based on how many headings precede the node.
  let headings = 0;
  for (let i = 0; i < nodeIndex; i++) {
    if (document.content && document.content[i].type === "heading") {
      headings++;
    }
  }
  return headings;
}

export function parseExerciseText(exerciseText: string) {
  let [exerciseName, setsText = ""] = exerciseText.split(":");
  let targetSets;
  try {
    targetSets = parseSetsText(setsText);
  } catch (error) {
    // Parsing failed, use a generic fallback
    targetSets = [
      {
        complete: false,
        selected: false,
        reps: 1,
        weight: null,
        seconds: null,
      },
    ];
  }

  return {
    exerciseName,
    targetSets,
  };
}

export function parseSetsText(setsText: string): Array<Set> {
  setsText = setsText.trim();

  // Regex black magic to ensure that the text is of a valid format.
  let setRegex = `(\\d+(\\.\\d+)?\\s*lbs?)|(\\d+(\\s*(${TIME_REGEX}))?(\\s*\\(\\d+(\\.\\d+)?(\\s*lbs?)?\\))?)`;
  // e.g. "8 seconds", "6(25lbs)", 6(25), or "5 lbs"
  let slashSetsRegex = new RegExp(`^${setRegex}(\/(${setRegex}))*$`);
  // e.g. "8(45lbs)/8(65lbs)/8(135lbs)"
  let duplicatedSetsRegex = new RegExp(`^\\d+x(${setRegex})$`);
  // e.g. "3x8(135lbs)"

  if (!slashSetsRegex.test(setsText) && !duplicatedSetsRegex.test(setsText)) {
    throw new Error("Sets text is invalid");
  }

  let sets: Array<Set> = [];

  if (setsText.includes("x")) {
    const [setCountText, setText] = setsText.trim().split("x");

    const setCount = Math.floor(convertTextToNumber(setCountText) || 1);

    for (let i = 0; i < setCount; i++) {
      sets.push(cloneDeep(parseSetText(setText.trim())));
    }
  } else {
    let setTexts = setsText.split("/");
    setTexts.forEach((setText) => {
      sets.push(cloneDeep(parseSetText(setText.trim())));
    });
  }

  if (sets.length === 0) {
    sets.push({
      complete: false,
      selected: false,
      reps: 1,
      weight: null,
      seconds: null,
    });
  }

  return sets;
}

export function parseSetText(setText: string): Set {
  setText = setText.trim();
  let set: Set = {
    complete: false,
    selected: false,
    reps: 1,
    weight: null,
    seconds: null,
  };

  // Start by parsing out weight.
  // Valid weight is of the format e.g. 10(100lbs) or 100lbs
  const oneRepWeightRegex = /^(\d+(\.\d+)?)\s*lbs?$/;
  const parenthesisWeightRegex = /\((\d+(\.\d+)?)\s*(lbs?)?\)$/;

  if (oneRepWeightRegex.test(setText) || parenthesisWeightRegex.test(setText)) {
    //No reps, so no parens needed. "100lbs"
    const oneRepMatch = setText.match(oneRepWeightRegex);
    if (oneRepMatch) {
      set.weight = convertTextToNumber(oneRepMatch[1]);
      //Truncate to 1 decimal place
      set.weight = Math.round(set.weight! * 10) / 10;
      return set;
    }

    const parenthesisMatch = setText.match(parenthesisWeightRegex);
    if (parenthesisMatch) {
      set.weight = convertTextToNumber(parenthesisMatch[1]);
      set.weight = Math.round(set.weight! * 10) / 10;
    }
    // Get rid of the parentheses and continue parsing
    setText = setText.replaceAll(/\((\d+(\.\d+)?)\s*(lbs?)?\)/g, "").trim();
  }

  if (isDuration(setText)) {
    set.seconds = getDurationSeconds(setText);
  } else if (isNumeric(setText)) {
    set.reps = Math.floor(convertTextToNumber(setText) || 1);
  }
  return set;
}

export function isDuration(text: string) {
  const regex = new RegExp(`^\\d+(${TIME_REGEX})$`, "i");
  return regex.test(text);
}

export function getDurationSeconds(text: string) {
  const regex = new RegExp(`^(\\d+)(${TIME_REGEX})$`, "i");
  const match = text.match(regex);

  if (!match) {
    throw new Error("Invalid duration format");
  }

  const time = parseFloat(match[1]);
  const unit = match[2].toLowerCase().trim();

  let seconds;

  switch (unit) {
    case "s":
    case "sec":
    case "second":
    case "seconds":
      seconds = time;
      break;
    case "m":
    case "min":
    case "minute":
    case "minutes":
      seconds = time * 60;
      break;
    default:
      throw new Error("Invalid duration unit");
  }

  return seconds;
}

export function getCompletedSetsFromNodeIndex(
  document: JSONContent,
  nodeIndex: number
): Array<Set> {
  let completedSetsNode = document.content?.[nodeIndex];
  if (completedSetsNode === undefined) return [];
  if (completedSetsNode.type !== "paragraph") return [];
  let completedSetsText = getNodeInnerText(completedSetsNode);

  try {
    return parseCompletedSetsText(completedSetsText || "");
  } catch (error) {
    // node is formatted incorrectly, so ignore it.
    return [];
  }
}

// needs to support times instead of reps. 45x5s, 45x2min
export function parseCompletedSetsText(completedSetsText: string): Array<Set> {
  if (completedSetsText === "") return [];
  return parseSetsText(completedSetsText);
}

export function getWorkoutFromDocument(document: JSONContent): Workout {
  let workout: Workout = {
    title: getTitleText(document) || "",
    exercises: [],
  };
  workout.exercises = getAllExerciseNodeIndexes(document).map(
    (nodeIndex, exerciseIndex) =>
      getExerciseFromNodeIndex(document, nodeIndex, exerciseIndex)
  );

  return workout;
}
